<template>
  <b-sidebar
    id="add-new-diagnostic-image-sidebar"
    :visible="isEditDiagnosticImageSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-edit-diagnostic-image-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">Edit DiagnosticImage</h5>

        <i class="fas fa-times ml-1 cursor-pointer" @click="hide"></i>
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="name"
            rules="required"
          >
            <b-form-group :label="$t('Form.Name')" label-for="name">
              <b-form-input
                id="name"
                v-model="stateData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="DiagnosticImage"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- price -->
          <validation-provider
            #default="validationContext"
            name="price"
            rules="required"
          >
            <b-form-group label="Price" label-for="price">
              <b-form-input
                id="price"
                v-model="stateData.price"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="price"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- price -->
          <validation-provider
            #default="validationContext"
            name="price cost"
            rules="required"
          >
            <b-form-group label="Price cost" label-for="price cost">
              <b-form-input
                id="price"
                v-model="stateData.price_cost"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="price"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- status -->
          <validation-provider
            #default="validationContext"
            name="Status"
            rules="required"
          >
            <b-form-group :label="$t('Form.Status')" label-for="status">
              <treeselect
                id="status"
                v-model="stateData.status"
                :state="getValidationState(validationContext)"
                :options="optionsStatus"
                :normalizer="normalizer"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="loading"
            >
              <span v-if="!loading">Edit</span>
              <span v-else>
                <SpinnerLoading />
              </span>
            </b-button>
            <b-button
              type="button"
              variant="outline-danger"
              @click="hide"
            >
              {{ $t("Cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import formValidation from "@/core/utils/forms/form-validation";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import SpinnerLoading from "@/components/SpinnerLoading";

import { required } from "@validations";
import { ref } from "@vue/composition-api";

import axiosAC from "@/core/services/api/admin/consults/diagnosticImages";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    SpinnerLoading,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  model: {
    prop: "isEditDiagnosticImageSidebarActive",
    event: "update:is-edit-diagnostic-image-sidebar-active",
  },
  props: {
    isEditDiagnosticImageSidebarActive: {
      type: Boolean,
      required: true,
    },
    itemEdit: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      required,
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
        };
      },
    };
  },
  setup(props, { emit }) {
    const optionsStatus = [
      { name: "Active", id: true },
      { name: "Inactive", id: false },
    ];
    const stateData = ref(JSON.parse(JSON.stringify(props.itemEdit.value)));
    const resetuserData = () => {
      stateData.value = JSON.parse(JSON.stringify(props.itemEdit.value));
    };

    const loading = ref(false);
    const onSubmit = () => {
      loading.value = true;
      const DiagnosticImageItem = JSON.parse(JSON.stringify(stateData));
      axiosAC
        .diagnosticImageUpdate(DiagnosticImageItem.value.id, DiagnosticImageItem.value)
        .then(() => {
          loading.value = false;
          resetuserData();
          emit("editDiagnosticImageSuccess", true);
        })
        .catch(() => {
          emit("editDiagnosticImageSuccess", false);
          loading.value = false;
        });
    };
    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetuserData
    );
    return {
      stateData,
      loading,
      optionsStatus,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style></style>
